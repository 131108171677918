<script>
export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {
      /****** Operational Variables *****/
      errorMessage: false,
      isSuccess: false,
      displayRequiredErrorForRegion: false
    };
  },

  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {},
  methods: {
    clickCancel() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <!-- Modal Title and Description-->
    <div class="row justify-content-center mt-2">
      <div class="col-md-12">
        <h5>Respondent Details:</h5>
        <div></div>

        <hr />
      </div>
    </div>

    <!-- Form Component -->
    <div class="row justify-content-center mt-2 mb-2" v-if="!this.isLoading">
      <!-- Data Row -->
      <div>
        <h1>Respondent Data Here</h1>
      </div>

      <!-- Button Row -->
      <div class="col-md-12 mt-4" v-if="!this.isSuccess">
        <b-button class="ml-2" variant="light" @click="clickCancel()"
          >Close
        </b-button>
      </div>
    </div>

    <!-- Loading -->
    <div class="row justify-content-center mt-3" v-if="this.isLoading">
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
