var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        { staticClass: "table-responsive mb-0" },
        [
          _c("b-table", {
            attrs: {
              items: _vm.monitoringData,
              fields: _vm.fieldsForMonitoringDataTable,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              bordered: true,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "cell(datafileId)",
                fn: function (data) {
                  return [_c("div", [_vm._v(_vm._s(data.value))])]
                },
              },
              {
                key: "cell(progress)",
                fn: function (scope) {
                  return [
                    scope.item.currentStatus === "COMPLETED"
                      ? _c("b-progress", {
                          attrs: {
                            value: 100,
                            max: 100,
                            variant: "success",
                            animated: "",
                          },
                        })
                      : _vm._e(),
                    scope.item.currentStatus !== "COMPLETED"
                      ? _c("b-progress", {
                          attrs: {
                            value: scope.item.progress,
                            max: 100,
                            variant: "success",
                            animated: "",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(performance)",
                fn: function (data) {
                  return [
                    _c("div", { staticClass: "datafileNameLimit" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            data.value.duration &&
                              _vm.millisToMinutesAndSeconds(data.value.duration)
                          ) +
                          " "
                      ),
                    ]),
                    _c("small", [_vm._v(" " + _vm._s(data.value.id) + " ")]),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: { variant: "light", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.openRespondent(scope.item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "bx bx-info-circle" })]
                        ),
                        scope.item.traceId
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "warning", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.traceItem(scope.item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "bx bx-right-arrow" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }