<script>
export default {
  props: {
    monitoringData: Array,
    fieldsForMonitoringDataTable: Array
  },
  methods: {
    millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + " min " + (seconds < 10 ? "0" : "") + seconds + " secs";
    },
    openRespondent(respondent) {
      console.log("openRespondent", respondent);
      // this.$emit("openRespondent", respondent);
    },
    traceItem(respondent) {
      const aspecto_workspace = process.env.VUE_APP_ASPECTO_WORKSPACE;
      window.open(
        `https://app.aspecto.io/${aspecto_workspace}/traces/${respondent.traceId}`,
        "_blank"
      );
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive mb-0">
        <b-table
          :items="monitoringData"
          :fields="fieldsForMonitoringDataTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :bordered="true"
        >
          <!-- Datafile  -->
          <template v-slot:cell(datafileId)="data">
            <!-- <div class="datafileNameLimit"> {{ data.value.title }} </div>
            <small> {{data.value.id}} </small> -->
            <div>{{ data.value }}</div>
          </template>

          <!-- Progress  -->
          <template v-slot:cell(progress)="scope">
            <!-- scope.item.currentStatus === "COMPLETED"? 100% -->
            <b-progress
              v-if="scope.item.currentStatus === 'COMPLETED'"
              :value="100"
              :max="100"
              variant="success"
              animated
            ></b-progress>
            <b-progress
              v-if="scope.item.currentStatus !== 'COMPLETED'"
              :value="scope.item.progress"
              :max="100"
              variant="success"
              animated
            ></b-progress>
          </template>

          <template v-slot:cell(performance)="data">
            <div class="datafileNameLimit">
              {{
                data.value.duration &&
                millisToMinutesAndSeconds(data.value.duration)
              }}
            </div>
            <small> {{ data.value.id }} </small>
          </template>

          <!-- Actions  -->
          <template v-slot:cell(actions)="scope">
            <div class="row">
              <b-button
                class="ml-2"
                variant="light"
                @click="openRespondent(scope.item)"
                size="sm"
              >
                <i class="bx bx-info-circle"></i>
              </b-button>

              <b-button
                v-if="scope.item.traceId"
                variant="warning"
                @click="traceItem(scope.item)"
                size="sm"
              >
                <i class="bx bx-right-arrow"> </i>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.datafileNameLimit {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>