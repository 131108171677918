var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: "Jobs Monitoring", items: _vm.computedItems },
      }),
      _vm.isPageLoading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "row justify-content-center mt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isPageLoading && _vm.isDataFetchingError
        ? _c("div", [_c("DataFetchingErrorWidget")], 1)
        : _vm._e(),
      !_vm.isPageLoading &&
      _vm.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
        ? _c("div", [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.isMonitoringDataSectionLoading
                    ? _c("div", { staticClass: "card-text" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row justify-content-center mt-5 mb-5",
                          },
                          [
                            _c("b-spinner", {
                              staticClass: "m-2",
                              staticStyle: { width: "5rem", height: "5rem" },
                              attrs: { variant: "mid", role: "status" },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  !_vm.isMonitoringDataSectionLoading &&
                  !_vm.isMonitoringDataFetchingError
                    ? _c(
                        "b-tabs",
                        { attrs: { "content-class": "pt-3" } },
                        [
                          _c(
                            "b-tab",
                            {
                              attrs: {
                                lazy: "",
                                active: this.activatedTab === "RESPONDENT",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeActivatedTab("OVERVIEW")
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-none d-sm-inline-block",
                                          },
                                          [
                                            _vm._v(
                                              " Respondent Monitoring Data "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                79249414
                              ),
                            },
                            [
                              _c("div", [
                                _c("div", { staticClass: "card-title mb-3" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-10" }, [
                                      _c("h5", [
                                        _vm._v(
                                          " Pending: " +
                                            _vm._s(
                                              `${this.incompleteRespondents} / ${this.totalNumberOfRespondents}`
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-2 text-right" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "light",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickOnReloadMonitoringData()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bx bx-sync mr-1",
                                            }),
                                            _vm._v(" Reload "),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "card-text" },
                                  [
                                    _c("MonitoringDataTable", {
                                      attrs: {
                                        fieldsForMonitoringDataTable:
                                          _vm.fieldsForRespMonitoringDataTable,
                                        monitoringData: this.respMonitoringData,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: {
                                lazy: "",
                                active: this.activatedTab === "DATAFILE",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeActivatedTab("DATAFILE")
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-none d-sm-inline-block",
                                          },
                                          [_vm._v(" Datafile Monitoring Data ")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3209323230
                              ),
                            },
                            [
                              _c("div", [
                                _c("div", { staticClass: "card-title mb-3" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-10" }, [
                                      _c("h5", [
                                        _vm._v(
                                          " Pending: " +
                                            _vm._s(
                                              `${this.incompleteDatafiles} / ${this.totalNumberOfDatafiles}`
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-2 text-right" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "light",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickOnReloadMonitoringData()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bx bx-sync mr-1",
                                            }),
                                            _vm._v(" Reload "),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "card-text" },
                                  [
                                    _c("MonitoringDataTable", {
                                      attrs: {
                                        fieldsForMonitoringDataTable:
                                          _vm.fieldsForDFMonitoringDataTable,
                                        monitoringData: this.dfMonitoringData,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isMonitoringDataSectionLoading &&
                  _vm.isMonitoringDataFetchingError
                    ? _c(
                        "div",
                        { staticClass: "card-text" },
                        [_c("DataFetchingErrorWidget")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.loggedInUser.accessLevel !== _vm.accessLevelEnum.SUPER_ADMIN
        ? _c("div", { staticClass: "row pt-5" }, [
            _c("div", { staticClass: "col-md-3" }),
            _c("div", { staticClass: "col-md-6 text-center" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/no-access.png"),
                  alt: "",
                  height: "50",
                },
              }),
              _c("div", { staticClass: "mt-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("adminPanel.noPermision")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-3" }),
          ])
        : _vm._e(),
      _vm.displaydisplayInfoModal === true
        ? _c("DisplayInfoModal", {
            attrs: { visible: _vm.displaydisplayInfoModal },
            on: { close: _vm.closeInfoModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }