import { ApiCall } from "@/api/apiBase.js";

class MonitoringService {
  /******** Get Monitoring Data With Pagination *********/
  async getMonitoringDataWithPagination(datafileId, page, limit) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getMonitoringDataWithPagination($filters: MonitoringFiltersInput!, $paginationInfo: PaginationInfoInput!) {
            getMonitoringDataWithPagination(filters: $filters, paginationInfo: $paginationInfo) {
              respondents {
                respondentId
                datafileId
                performance
                requestBody
                responseBody
                startingTime
                lastUpdated
                currentStatus
                traceId
                progress
                usingFeedbacksDB
                usingGPT4
                totalNumberOfSteps
              }
              totalNumberOfRespondents
              incompleteRespondents
              datafiles {
                datafileKey
                performance
                startingTime
                lastUpdated
                currentStatus
                traceId
                progress
                usingFeedbacksDB
                usingGPT4
                totalNumberOfSteps
              }
              totalNumberOfDatafiles
              incompleteDatafiles
            }
          }
        `,
        variables: {
          filters: {
            datafileId: datafileId
          },
          paginationInfo: {
            page: page,
            limit: limit
          }
        }
      }
    });

    if (response?.data?.data?.getMonitoringDataWithPagination && !response?.data?.errors) {
      return response?.data?.data?.getMonitoringDataWithPagination;
    } else {
      return response?.data?.errors[0];
    }
  }
}

export default new MonitoringService();